// import Inferno from 'inferno';
import _ from 'lodash';
// import Component from 'inferno-component';
import React, {useCallback, useState} from 'react';
import Helmet from 'react-helmet';
import {useStream} from '../hooks/useStream';

import constants from '../internals/constants';
import I18n from '../libs/I18n';
import appStateStream from '../streams/appStateStream';
import createOrderItemActionStream from '../streams/createOrderItemActionStream';
import dataStream from '../streams/dataStream';
import languageStream from '../streams/languageStream';
import openStepActionStream from '../streams/openStepActionStream';
import orderStream from '../streams/orderStream';
import selectMaterialActionStream from '../streams/selectMaterialActionStream';
import selectProfileActionStream from '../streams/selectProfileActionStream';
import selectWindowActionStream from '../streams/selectWindowActionStream';
import findDefaultMaterial from '../utils/findDefaultMaterial';
import findDefaultProfile from '../utils/findDefaultProfile';
import AccessoriesStep from './AccessoriesStep';
import Button from './Button';
import ColorStep from './ColorStep';
import GlassStep from './GlassStep';

import styles from './Main.css';
import MaterialStep from './MaterialStep';
import OrderStep from './OrderStep';
import ProfileStep from './ProfileStep';
import StepsList from './StepsList';
import WindowStep from './WindowStep';
import WindowVisualization from './WindowVisualization';

const {CS_CZ} = constants;
const {SK_SK} = constants;

let i18n = new I18n();

export let Main = () => {
	useStream(languageStream);
	useStream(appStateStream);
	useStream(orderStream);
	const [materialModal, setMaterialModal] = useState(false);
	
	let handleStartClick = useCallback(() => {
		let order = orderStream.value;
		if (!order.items.length) {
			createOrderItemActionStream.push(true);
		}
		
		openStepActionStream.push('material');
	}, []);
	
	let {openedStep} = appStateStream.value;
	let order = orderStream.value;
	let orderItem;
	
	if (order.currentItem !== null) {
		orderItem = order.items[order.currentItem];
	}
	
	// console.log(order);
	// console.log(orderItem);
	
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	// console.log('urlParams', urlParams, urlParams.size, urlParams.size > 0);
	
	if (urlParams.size) {
		// console.log(order.items.length);
		if (!order.items.length) {
			createOrderItemActionStream.push(true);
			if (order.currentItem !== null) {
				orderItem = order.items[order.currentItem];
			}
		}
		
		if (orderItem) {
			if (!orderItem.window) {
				const selectedMaterial = findDefaultMaterial(dataStream.value.materials, 1);
				selectMaterialActionStream.push({
					id: selectedMaterial.id,
					name: selectedMaterial.name
				});
				
				let filteredWindowPrice = dataStream.value.windowPrices.find(
					(windowPrice) => selectedMaterial.id === windowPrice.materialId
				);
				
				let minWidth = filteredWindowPrice && Math.min(...filteredWindowPrice.sizes.map((windowSize) => windowSize.widths[0]));
				let maxWidth = filteredWindowPrice && Math.max(...filteredWindowPrice.sizes.map((windowSize) => windowSize.widths[1]));
				let minHeight = filteredWindowPrice && Math.min(...filteredWindowPrice.sizes.map((windowSize) => windowSize.heights[0]));
				let maxHeight = filteredWindowPrice && Math.max(...filteredWindowPrice.sizes.map((windowSize) => windowSize.heights[1]));
				
				let width = urlParams.has('w') ? parseInt(urlParams.get('w'), 10) : 0;
				if (isNaN(width) || width === 0 || width < minWidth || width > maxWidth) {
					width = minWidth;
				}
				
				let height = urlParams.has('h') ? parseInt(urlParams.get('h'), 10) : 0;
				if (isNaN(height) || height === 0 || height < minHeight || height > maxHeight) {
					height = minHeight;
				}
				
				let orientation = urlParams.has('o') ? urlParams.get('o') : 1;
				let variant = 1;
				switch (orientation) {
					case 1:
					case 'l':
					default:
						variant = 1;
						break;
					case 2:
					case 'r':
						variant = 2;
						break;
				}
				
				let filteredWindowSizes = [];
				if (filteredWindowPrice) {
					filteredWindowSizes = filteredWindowPrice.sizes.filter(
						(windowSize) => width >= windowSize.widths[0] &&
							width <= windowSize.widths[1] &&
							height >= windowSize.heights[0] &&
							height <= windowSize.heights[1]
					);
				}
				
				let filteredWindowRecommendations = dataStream.value.windowRecommendations.filter(
					(windowRecommendation) => width >= windowRecommendation.widths[0] &&
						width <= windowRecommendation.widths[1] &&
						height >= windowRecommendation.heights[0] &&
						height <= windowRecommendation.heights[1]
				);
				
				let filteredWindowTypes = filteredWindowSizes.map((windowSize) => _.find(dataStream.value.windowTypes, {type: windowSize.type}));
				
				let selectedWindowSize = undefined;
				let selectedWindowSizeIndex = undefined;
				filteredWindowSizes.some((windowSize, windowSizeIndex) => {
					if (filteredWindowRecommendations[0].types.includes(windowSize.type)) {
						selectedWindowSize = windowSize;
						selectedWindowSizeIndex = windowSizeIndex;
					}
				});
				
				if(selectedWindowSizeIndex === undefined) {
					selectedWindowSizeIndex = 0;
				}
				let selectedWindowType = filteredWindowTypes[selectedWindowSizeIndex];
				
				const selectedProfile = findDefaultProfile(dataStream.value.profiles, selectedWindowType, selectedMaterial.id);
				selectProfileActionStream.push({
					id: selectedProfile.id,
					name: selectedProfile.name,
					priceModifier: selectedProfile.priceModifier,
					rebate: selectedProfile.rebate
				});
				
				selectWindowActionStream.push({
					typeId: selectedWindowType.id,
					sizeId: selectedWindowSize.id,
					materialId: selectedMaterial.id,
					profileId: selectedProfile.id,
					type: selectedWindowType.type,
					width,
					height,
					price: selectedWindowSize.price,
					hasLeftRightVariants: selectedWindowType.hasLeftRightVariants,
					variant
				});
				
				openStepActionStream.push('order');
			}
		}
		
		// console.log('urlparams-order', order);
		// console.log('urlparams-orderitem', orderItem);
	}
	
	// console.log("order after", order);
	// console.log("orderItem after", orderItem);
	
	return (
		<main className={styles.root}>
			{/* <Helmet> */}
			{/* 	<title>{i18n.translate`Html - title`}</title> */}
			{/* 	<meta name="description" content={i18n.translate`Html - description`} /> */}
			{/* </Helmet> */}
			{orderItem && orderItem.window && orderItem.window.typeId ?
				<div className={styles.visualization}>
					<WindowVisualization orderItem={orderItem} />
				</div> :
				<div className={styles.intro}>
					<h1 className={styles.appHeading}>{i18n.translate`Main - intro - heading`}</h1>
					<div className={styles.appDescription}>
						<p>{i18n.translate`Main - intro - text 1`}</p><br/>
						<p>{i18n.translate`Main - intro - text 2`}</p>
					</div>
					<p className={styles.buttons}>
						<Button
							type="full"
							color="inverse"
							size="normal"
							label={i18n.translate`Main - intro - button start`}
							handleClick={handleStartClick}
						/>
						{languageStream.value.current === CS_CZ || languageStream.value.current === SK_SK ?
							<Button
								type="outlined"
								color="inverse"
								size="normal"
								label={i18n.translate`Main - intro - button videotutorial`}
								link="https://www.youtube.com/watch?v=EPMNmCnhHOg"
							/> :
							null}
					</p>
				</div>
			}
			<StepsList /* setMaterialModal={setMaterialModal} */ />
			<WindowStep isOpen={openedStep === 'window'} /* materialModal={materialModal} setMaterialModal={setMaterialModal} */ />
			<MaterialStep isOpen={openedStep === 'material'} />
			<ProfileStep isOpen={openedStep === 'profile'} />
			<GlassStep isOpen={openedStep === 'glass'} />
			<ColorStep isOpen={openedStep === 'color'} />
			<AccessoriesStep isOpen={openedStep === 'accessories'} />
			<OrderStep isOpen={openedStep === 'order'} />
		</main>
	);
};
